import danish from "translations/languages/da.json";
import english from "translations/languages/en.json";
import spanish from "translations/languages/es.json";
import finnish from "translations/languages/fi.json";
import norwegian from "translations/languages/nb.json";
import swedish from "translations/languages/sv.json";
import dutch from "translations/languages/nl.json";

export const resources = {
    en: {
        translation: english,
    },
    sv: {
        translation: swedish,
    },
    nb: {
        translation: norwegian,
    },
    da: {
        translation: danish,
    },
    fi: {
        translation: finnish,
    },
    es: {
        translation: spanish,
    },
    nl: {
        translation: dutch,
    },
    // et: {
    //     translation: estonian,
    // },
    // ru: {
    //     translation: russian,
    // },
    // fr: {
    //     translation: french,
    // },
    // nl: {
    //     translation: dutch,
    // },
} as const;

export const supportedLanguages = Object.keys(resources) as Array<keyof typeof resources>;
